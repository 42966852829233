<template>
	<div class="group" v-title="'品牌简介——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
		<div class="box1">
			<img class="bran" src="../image/about/group_banner.jpg" alt="" />
		</div>
		<div class="box-mob">
            <img
				class="bran"
				src="../image/about/group_banner.jpg"
				alt=""
			/>
		</div>
		<div class="content">
			<div class="nav-list">
				<AboutNav></AboutNav>
			</div>
			<div class="box f-y-s-c">
				<div class="part1-mob">
					<img
						class="img"
						src="../image/about/group_part1.jpg"
						alt=""
					/>
					<div class="info">
						<div class="header f-x-s-c">
							<img
								class="img1"
								src="../image/about/group_box1_icon.png"
								alt=""
							/>
							<!-- <span class="text1"
								>&nbsp;——&nbsp;Brand Profile</span
							> -->
						</div>
						<div class="bottom">
							<p class="text2">
								千家美，全称千家美全包装修，隶属于上海茗居网络科技有限公司。品牌以全包装修模式为主打，集中整合设计、选材、施工、软装等多个装修环节，打通装修链路，让家装过程更加透明、便利。
							</p>
							<p class="text3">
								千家美致力于为消费者打造良好的家装体验，联合众多设计师，以全景效果设计，实景样板房为原型，打造新一代家装设计系统。前沿的创新理念，健康环保的装修材料与高标准的施工工艺，全方位为消费者打造理想新家。
							</p>
						</div>
					</div>
				</div>
                <div class="part1-pc">
                    <div class="img-box">
                        <img
                            class="img"
                            src="../image/about/group_part1_pc.jpg"
                            alt=""
                        />
                    </div>
					
					<div class="info f-y-s-s">
                        <div class="top ">
                            <span class="title">品牌概况</span>
                            <div class="cus-line"></div>
                        </div>
						<div class="bottom">
							<p class="text2">
								千家美，全称千家美全包装修，隶属于上海茗居网络科技有限公司。品牌以全包装修模式为主打，集中整合设计、选材、施工、软装等多个装修环节，打通装修链路，让家装过程更加透明、便利。
							</p>
							<p class="text3">
								千家美致力于为消费者打造良好的家装体验，联合众多设计师，以全景效果设计，实景样板房为原型，打造新一代家装设计系统。前沿的创新理念，健康环保的装修材料与高标准的施工工艺，全方位为消费者打造理想新家。
							</p>
						</div>
					</div>
				</div>
				<div class="part2 f-x-s-c">
					<div class="info">
						<span class="text1">经营理念</span>
						<div class="cus-line"></div>
						<p class="text2">
							千家美是上海茗居网络科技有限公司旗下战略品牌。涵盖整体装修、局部改造、软装换新等业务板块，助力上海装修业主一站式打造理想新家。
						</p>
					</div>
				</div>
				<div class="part3">
					<div class="header">
						<span class="text1">品牌特点&nbsp;·&nbsp;十全十美</span>
						<div class="cus-line"></div>
					</div>
					<div class="list">
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon1.png"
								alt=""
							/>
							<span class="text2">服务全</span>
							<span class="text3">1对1专属管家</span>
							<span class="text4">尽心服务</span>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon2.png"
								alt=""
							/>
							<span class="text2">团队全</span>
							<span class="text3">囊括设计、施工等家装各个团队</span>
							<!-- <span class="text4">装各个团队</span> -->
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon3.png"
								alt=""
							/>
							<span class="text2">监管全</span>
							<span class="text3">专业监理全程监督层层把关</span>
							<!-- <span class="text4">层层把关</span> -->
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon4.png"
								alt=""
							/>
							<span class="text2">环节全</span>
							<span class="text3">从开始到售后，一步到位</span>
							<!-- <span class="text4">一步到位</span> -->
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon5.png"
								alt=""
							/>
							<span class="text2">售后全</span>
							<span class="text3">24小时热线</span>
							<span class="text4">24小时内及时响应</span>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon6.png"
								alt=""
							/>
							<span class="text2">制度全</span>
							<span class="text3">员工收入与服务品质直接挂钩</span>
							<!-- <span class="text4">直接挂钩</span> -->
						</div>

						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon7.png"
								alt=""
							/>
							<span class="text2">规范全</span>
							<span class="text3">108项施工规范</span>
							<span class="text4">保障质量</span>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon8.png"
								alt=""
							/>
							<span class="text2">经验全</span>
							<span class="text3">知名家装品牌班底</span>
							<span class="text4">经验丰富</span>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon9.png"
								alt=""
							/>
							<span class="text2">产品全</span>
							<span class="text3">百家一线品牌直供</span>
							<span class="text4">一站购齐</span>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon10.png"
								alt=""
							/>
							<span class="text2">承诺全</span>
							<span class="text3">坚定做出八大承诺</span>
							<span class="text4">做良心企业</span>
						</div>

                        <div class="mob-show list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon11.png"
								alt=""
							/>
							<span class="text2">设计美</span>
							<span class="text3">联合知名设计师 </span>
							<span class="text4">兼具实用与美感</span>
						</div>
						<div class="mob-show list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon12.png"
								alt=""
							/>
							<span class="text2">价格美</span>
							<span class="text3">高性价比产品组合 </span>
							<span class="text4">实惠到家</span>
						</div>
					</div>
					<img
						class="img2"
						src="../image/about/group_part3.jpg"
						alt=""
					/>
					<div class="list2">
						<div class="pc-show list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon11.png"
								alt=""
							/>
							<span class="text2">设计美</span>
							<span class="text3">联合知名设计师 </span>
							<span class="text4">兼具实用与美感</span>
						</div>
						<div class="pc-show list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon12.png"
								alt=""
							/>
							<span class="text2">价格美</span>
							<span class="text3">高性价比产品组合 </span>
							<span class="text4">实惠到家</span>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon13.png"
								alt=""
							/>
							<span class="text2">品质美</span>
							<span class="text3">多重质检标准 </span>
							<span class="text4">不达标，砸无赦</span>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon14.png"
								alt=""
							/>
							<span class="text2">环保美</span>
							<span class="text3">构建绿色供应链 </span>
							<span class="text4">打造环保新家</span>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon15.png"
								alt=""
							/>
							<span class="text2">选材美</span>
							<span class="text3">12道产品严选标准 </span>
							<span class="text4">耐用实惠</span>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon16.png"
								alt=""
							/>
							<span class="text2">品牌美</span>
							<span class="text3">沪上头部装企子品牌 深受好评</span>
							<!-- <span class="text4">深受好评</span> -->
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon17.png"
								alt=""
							/>
							<span class="text2">体验美</span>
							<span class="text3">实景体验+全景效果 所见即所得 </span>
							<!-- <span class="text4">所见即所得</span> -->
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon18.png"
								alt=""
							/>
							<span class="text2">工艺美</span>
							<span class="text3">十大紫钻工艺 </span>
							<span class="text4">品质有保障</span>
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon19.png"
								alt=""
							/>
							<span class="text2">诚信美</span>
							<span class="text3">不主动增项、不忽悠 假一罚十</span>
							<!-- <span class="text4">假一罚十</span> -->
						</div>
						<div class="list-item f-y-c-c">
							<img
								class="img"
								src="../image/about/group_icon20.png"
								alt=""
							/>
							<span class="text2">人性美</span>
							<span class="text3">投诉电话直达 </span>
							<span class="text4">总裁办公室</span>
						</div>
					</div>
				</div>
				<div class="part4">
					<div class="header">
						<span class="text1">省心家装服务</span>
						<div class="cus-line"></div>
					</div>
					<div class="list f-x-b-c">
						<div class="list-item f-y-s-c">
							<img
								class="img"
								src="../image/about/group_part4_1.jpg"
								alt=""
							/>
							<span class="text2">专属设计</span>
							<span class="text3">1对1专属咨询 </span>
							<span class="text4">实景体验馆参观</span>
						</div>
						<div class="list-item f-y-s-c">
							<img
								class="img"
								src="../image/about/group_part4_2.jpg"
								alt=""
							/>
							<span class="text2">施工验收</span>
							<span class="text3">专业监理 全程监督 </span>
							<span class="text4">5重验收 层层把关</span>
						</div>
						<div class="list-item f-y-s-c">
							<img
								class="img"
								src="../image/about/group_part4_3.jpg"
								alt=""
							/>
							<span class="text2">售后服务</span>
							<span class="text3">24小时售后热线，2小时急速 响应交付后1年内2次回访</span>
							<!-- <span class="text4">响应交付后1年内2次回访</span> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<Footer></Footer>

		<!-- 右浮窗和底部吸顶 -->
		<Floating></Floating>
		<FloatingMob></FloatingMob>
		<VisitorForm3></VisitorForm3>
		<Tabbar></Tabbar>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import AboutNav from '../components/AboutNav.vue'
export default {
	components: {
		NavBar,
		Footer,
		AboutNav,
	},
	data() {
		return {
			addressList: [
				{
					address: '上海市奉贤区南奉公路7500号苏宁生活广场3F',
					title: '奉贤店',
					position: {
						lng: '121.495517',
						lat: '30.924118',
					},
					url: require('../image/about/address1.jpg'),
				},
				{
					address: '上海市静安区沪太路855号 ',
					title: '静安店',
					position: {
						lng: '121.44574',
						lat: '31.275387',
					},
					url: require('../image/about/address2.jpg'),
				},
				{
					address: '河南省郑州市金水区花园路8号正道花园百货3F~5F',
					title: '郑州店',
					position: {
						lng: '113.689338',
						lat: '34.774986',
					},
					url: require('../image/about/address3.jpg'),
				},
			],
		}
	},
	methods: {},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
	.group {
        overflow-x: hidden;
		.box1 {
			display: none;
		}
		.box-mob {
			width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
        .content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
            flex-flow: column;
            .nav-list{
                width: 100%;
            }
			.box {
				width: 100%;
				background: #ffffff;
				border-radius: 9px;
                .part1-pc{
                    display: none;
                }
				.part1-mob {
                    position: relative;
					width: 100%;
					box-sizing: border-box;
					display: flex;
					align-items: center;
                    flex-flow: column;
					.img {
						width: 100%;
						height: 280px;
                        object-fit: cover;
					}
					.info {
                        // position: absolute;
                        // left: calc((100% - 350px)/2);
                        // top: 250px;
                        margin-top: -30px;
						display: flex;
						flex-flow: column;
						width: 350px;
						// height: 210px;
						background: #ffffff;
						opacity: 0.95;
						padding: 15px;
						box-sizing: border-box;
						.header {
							.img1 {
								// width: 80px;
                                width: 100%;
								height: 30px;
                                object-fit: cover;
							}
							.text1 {
								font-weight: 400;
								font-size: 15px;
								color: #753cbe;
								line-height: 15px;
							}
						}
						.bottom {
							margin-top: 10px;
							.text2 {
								
								font-weight: 300;
								font-size: 12px;
								color: #333333;
								line-height: 18px;
							}
							.text3 {
								margin-top: 3px;
								
								font-weight: 300;
								font-size: 12px;
								color: #333333;
								line-height: 18px;
							}
						}
					}
				}
				.part2 {
					width: 100%;
					margin-top: 20px;
					height: 180px;
					background:center/cover url(../image/about/group_part2.jpg);
					// background-size: 100% 100%;
					padding: 10px;
					box-sizing: border;
					box-sizing: border-box;
					.info {
						width: 200px;
						// height: 259px;
						background: #ffffff;
						opacity: 0.98;
						padding: 10px;
						box-sizing: border-box;
						.text1 {
							line-height: 15px;
							
							font-weight: bold;
							font-size: 15px;
							color: #241934;
						}
						.cus-line {
							width: 60px;
							height: 2px;
							background: #753cbe;
							margin-top: 5px;
						}
						.text2 {
							
							font-weight: 300;
							font-size: 12px;
							color: #333333;
							line-height: 16px;
							margin-top: 10px;
						}
					}
				}
				.part3 {
					display: flex;
					flex-flow: column;
					align-items: center;
					width: 100%;
					margin-top: 50px;
					padding: 0 10px;
					box-sizing: border-box;
					.header {
                        display: flex;
                        flex-flow: column;
                        align-items: center;
						.text1 {
							
							font-weight: bold;
							font-size: 16px;
							color: #241934;
						}
						.cus-line {
							width: 140px;
							height: 2px;
							background: #753cbe;
							margin-top: 8px;
						}
					}
                    .pc-show{
                       display: none; 
                    }
					.list,
					.list2 {
						margin-top: 20px;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
                        display: grid;
                        grid-template-columns: repeat(4,1fr);
                        gap: 10px;
						.list-item {
							width: 100%;
							height: 100px;
							background: #f7f7f7;
							border-radius: 2px;
							padding: 2px;
							box-sizing: border-box;
							.img {
								width: 25px;
								height: 25px;
							}
							.text2 {
								
								font-weight: bold;
								font-size: 12px;
								color: #753cbe;
								line-height: 12px;
								margin-top: 5px;
							}
							.text3 {
								margin-top: 3px;
								
								font-weight: 400;
								font-size:9px;
								color: #555555;
								line-height: 16px;
                                text-align: center;
							}
							.text4 {
								// margin-top: 3px;
								
								font-weight: 400;
								font-size: 9px;
								color: #555555;
								line-height: 16px;
							}
						}
					}
					.list2 {
						margin-top: 15px;
					}
					.img2 {
						width: 100%;
						height: 90px;
						margin-top: 15px;
                        object-fit: cover;
					}
				}
				.part4 {
					display: flex;
					flex-flow: column;
					align-items: center;
					width: 100%;
					margin-top: 50px;
					padding: 0 10px;
					box-sizing: border-box;
					.header {
                        display: flex;
                        flex-flow: column;
                        align-items: center;
						.text1 {
							
							font-weight: bold;
							font-size: 16px;
							color: #241934;
						}
						.cus-line {
							width: 90px;
							height: 2px;
							background: #753cbe;
							margin-top: 8px;
						}
					}
					.list {
						margin-top: 20px;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						width: 100%;
						.list-item {
							width: 110px;
							height: 200px;
							box-sizing: border-box;
							.img {
								width: 100%;
								height: 90px;
                                object-fit: cover;
							}
							.text2 {
                                padding: 0 3px;
								
								font-weight: bold;
								font-size: 16px;
								color: #644196;
								margin-top: 8px;
							}
							.text3 {
                                padding: 0 3px;
								margin-top: 5px;
								
								font-weight: 400;
								font-size: 10px;
								color: #555555;
								line-height: 14px;
							}
							.text4 {
                                padding: 0 3px;
								
								font-weight: 400;
								font-size: 10px;
								color: #555555;
								line-height: 14px;
							}
						}
					}
				}
			}
		}
	}
}


@media screen and (min-width: 900px) and(max-width:1359px) {
	.group {
		height: 100%;
		.box1 {
			width: 100%;
			height: 420px;
			// margin-top: 96px;
		}
		.box-mob {
			display: none;
		}
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 60px 2% 180px 2%;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.box {
                flex: 1;
				// width: 1062px;
				background: #ffffff;
				border-radius: 18px;
				margin-left: 50px;
				.part1-mob {
                    display: none;
					// width: 100%;
					// padding: 42px;
					// box-sizing: border-box;
					// display: flex;
					// align-items: center;
					// .img {
					// 	width: 700px;
					// 	height: 600px;
					// }
					// .info {
					// 	display: flex;
					// 	flex-flow: column;
					// 	width: 605px;
					// 	height: 450px;
					// 	background: #ffffff;
					// 	opacity: 0.95;
					// 	padding: 35px 10px 35px 35px;
					// 	box-sizing: border-box;
					// 	margin-left: -250px;
					// 	.header {
					// 		.img1 {
					// 			// width: 159px;
					// 			width: 100%;
					// 			height: 55px;
                    //             object-fit: cover;
					// 		}
					// 		.text1 {
					// 			font-weight: 400;
					// 			font-size: 36px;
					// 			color: #753cbe;
					// 			line-height: 36px;
					// 		}
					// 	}
					// 	.bottom {
					// 		margin-top: 20px;
					// 		.text2 {
								
					// 			font-weight: 300;
					// 			font-size: 25px;
					// 			color: #333333;
					// 			line-height: 31px;
					// 		}
					// 		.text3 {
					// 			margin-top: 15px;
								
					// 			font-weight: 300;
					// 			font-size: 25px;
					// 			color: #333333;
					// 			line-height: 31px;
					// 		}
					// 	}
					// }
				}
                .part1-pc{
                    width: 100%;
                    padding: 42px;
					box-sizing: border;
					box-sizing: border-box;
                    .img-box{
                        width: 100%;
						height: 900px;
                        overflow: hidden;
                        border-radius: 18px;
                        .img {
                            width: 100%;
                            height:100%;
                            object-fit: cover;
                        }
                    }
                    
                    .info{
                        .top{
                            .title{
                                height: 35px;
                                font-family: Source Han Sans SC;
                                font-weight: bold;
                                font-size: 36px;
                                color: #241934;
                            }
                            .cus-line {
                                width: 139px;
                                height: 6px;
                                background: #753cbe;
                                margin-top: 16px;
                            }
                        }
                        
                        .bottom{
                            width: 100%;
                            margin-top: 20px;
                            .text2{
                                font-family: Source Han Sans SC;
                                font-weight: 400;
                                font-size: 24px;
                                color: #333333;
                                line-height: 36px;
                            }
                            .text3{
                                margin-top: 40px;
                                font-family: Source Han Sans SC;
                                font-weight: 400;
                                font-size: 24px;
                                color: #333333;
                                line-height: 36px;
                            }
                        }
                    }
                }
				.part2 {
					width: 100%;
					margin-top: 20px;
					height: 432px;
					background: url(../image/about/group_part2.jpg);
					background-size: 100% 100%;
					padding: 42px;
					box-sizing: border;
					box-sizing: border-box;
					.info {
						width: 427px;
						// height: 259px;
						background: #ffffff;
						opacity: 0.98;
						padding: 34px 29px;
						box-sizing: border-box;
						.text1 {
							height: 35px;
							
							font-weight: bold;
							font-size: 36px;
							color: #241934;
						}
						.cus-line {
							width: 139px;
							height: 6px;
							background: #753cbe;
							margin-top: 16px;
						}
						.text2 {
							font-weight: 300;
							font-size: 25px;
							color: #333333;
							line-height: 31px;
							margin-top: 22px;
						}
					}
				}
				.part3 {
					display: flex;
					flex-flow: column;
					align-items: center;
					width: 100%;
					margin-top: 130px;
					padding: 0 47px;
					box-sizing: border-box;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 344px;
							height: 6px;
							background: #753cbe;
							margin-top: 15px;
						}
					}
                    .mob-show{
                       display: none; 
                    }
					.list,
					.list2 {
						margin-top: 50px;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;


                        display: grid;
                        grid-template-columns: repeat(5,1fr);
                        gap: 19px;
						.list-item {
							// width: 178px;
                            width: 100%;
							height: 220px;
							background: #f7f7f7;
							border-radius: 4px;
							padding: 15px;
							// margin-bottom: 19px;
							box-sizing: border-box;
							.img {
								width: 70px;
								height: 60px;
                                object-fit: cover;
							}
							.text2 {
								
								font-weight: bold;
								font-size: 28px;
								color: #753cbe;
								line-height: 28px;
								margin-top: 19px;
							}
							.text3 {
								margin-top: 16px;
								
								font-weight: 400;
								font-size: 20px;
								color: #555555;
                                line-height: 24px;
                                text-align: center;
							}
							.text4 {
								// margin-top: 5px;
								
								font-weight: 400;
								font-size: 20px;
								color: #555555;
								line-height: 24px;
							}
						}
					}
					.list2 {
						margin-top: 35px;
					}
					.img2 {
						width: 100%;
						height: 250px;
						margin-top: 15px;
					}
				}
				.part4 {
					display: flex;
					flex-flow: column;
					align-items: center;
					width: 100%;
					margin-top: 130px;
					padding: 0 47px 40px 47px;
					box-sizing: border-box;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 240px;
							height: 6px;
							background: #753cbe;
							margin-top: 15px;
						}
					}
					.list {
						margin-top: 40px;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						width: 100%;
						.list-item {
							width: 420px;
							// height: 500px;
							padding: 15px;
							box-sizing: border-box;
							.img {
								width: 100%;
								height: 300px;
                                object-fit: cover;
							}
							.text2 {
								
								font-weight: bold;
								font-size: 35px;
								color: #644196;
								margin-top: 23px;
							}
							.text3 {
								margin-top: 21px;
								
								font-weight: 400;
								font-size: 25px;
								color: #555555;
								line-height: 35px;
							}
							.text4 {
								// margin-top: 10px;
								
								font-weight: 400;
								font-size: 25px;
								color: #555555;
								line-height: 35px;
							}
						}
					}
				}
			}
		}
	}
}
@media screen and (min-width: 1360px) {
	.group {
		height: 100%;
		.box1 {
			width: 100%;
			height: 420px;
			// margin-top: 96px;
		}
		.box-mob {
			display: none;
		}
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 60px 60px 180px 60px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.box {
				width: 1062px;
				background: #ffffff;
				border-radius: 18px;
				margin-left: 50px;
				.part1-mob {
                    display: none;
					// width: 100%;
					// padding: 42px;
					// box-sizing: border-box;
					// display: flex;
					// align-items: center;
					// .img {
					// 	width: 700px;
					// 	height: 500px;
					// }
					// .info {
					// 	display: flex;
					// 	flex-flow: column;
					// 	width: 505px;
					// 	height: 392px;
					// 	background: #ffffff;
					// 	opacity: 0.95;
					// 	padding: 35px 10px 35px 35px;
					// 	box-sizing: border-box;
					// 	margin-left: -250px;
					// 	.header {
					// 		.img1 {
					// 			// width: 159px;
					// 			width: 100%;
					// 			height: 36px;
                    //             object-fit: cover;
					// 		}
					// 		.text1 {
					// 			font-weight: 400;
					// 			font-size: 31px;
					// 			color: #753cbe;
					// 			line-height: 31px;
					// 		}
					// 	}
					// 	.bottom {
					// 		margin-top: 20px;
					// 		.text2 {
								
					// 			font-weight: 300;
					// 			font-size: 20px;
					// 			color: #333333;
					// 			line-height: 27px;
					// 		}
					// 		.text3 {
					// 			margin-top: 10px;
								
					// 			font-weight: 300;
					// 			font-size: 20px;
					// 			color: #333333;
					// 			line-height: 27px;
					// 		}
					// 	}
					// }
				}
                .part1-pc{
                    width: 100%;
                    padding: 42px;
					box-sizing: border;
					box-sizing: border-box;
                    .img-box{
                        width: 100%;
						height: 670px;
                        overflow: hidden;
                        border-radius: 18px;
                        .img {
                            width: 100%;
                            height:100%;
                            object-fit: cover;
                        }
                    }
                    
                    .info{
                        .top{
                            .title{
                                height: 35px;
                                font-family: Source Han Sans SC;
                                font-weight: bold;
                                font-size: 36px;
                                color: #241934;
                            }
                            .cus-line {
                                width: 139px;
                                height: 6px;
                                background: #753cbe;
                                margin-top: 16px;
                            }
                        }
                        
                        .bottom{
                            width: 100%;
                            margin-top: 20px;
                            .text2{
                                font-family: Source Han Sans SC;
                                font-weight: 400;
                                font-size: 24px;
                                color: #333333;
                                line-height: 36px;
                            }
                            .text3{
                                margin-top: 40px;
                                font-family: Source Han Sans SC;
                                font-weight: 400;
                                font-size: 24px;
                                color: #333333;
                                line-height: 36px;
                            }
                        }
                    }
                }
				.part2 {
					width: 100%;
					margin-top: 20px;
					height: 332px;
					background: url(../image/about/group_part2.jpg);
					background-size: 100% 100%;
					padding: 42px;
					box-sizing: border;
					box-sizing: border-box;
					.info {
						width: 427px;
						// height: 259px;
						background: #ffffff;
						opacity: 0.98;
						padding: 34px 29px;
						box-sizing: border-box;
						.text1 {
							height: 35px;
							
							font-weight: bold;
							font-size: 36px;
							color: #241934;
						}
						.cus-line {
							width: 139px;
							height: 6px;
							background: #753cbe;
							margin-top: 16px;
						}
						.text2 {
							height: 109px;
							
							font-weight: 300;
							font-size: 20px;
							color: #333333;
							line-height: 27px;
							margin-top: 22px;
						}
					}
				}
				.part3 {
					display: flex;
					flex-flow: column;
					align-items: center;
					width: 100%;
					margin-top: 130px;
					padding: 0 47px;
					box-sizing: border-box;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 344px;
							height: 6px;
							background: #753cbe;
							margin-top: 15px;
						}
					}
                    .mob-show{
                       display: none; 
                    }
					.list,
					.list2 {
						margin-top: 50px;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						.list-item {
							width: 178px;
							height: 178px;
							background: #f7f7f7;
							border-radius: 4px;
							padding: 15px;
							margin-bottom: 19px;
							box-sizing: border-box;
							.img {
								width: 58px;
								height: 51px;
                                object-fit: cover;
							}
							.text2 {
								
								font-weight: bold;
								font-size: 24px;
								color: #753cbe;
								line-height: 24px;
								margin-top: 15px;
							}
							.text3 {
								margin-top: 12px;
								
								font-weight: 400;
								font-size: 16px;
								color: #555555;
                                line-height: 20px;
                                text-align: center;
							}
							.text4 {
								// margin-top: 5px;
								
								font-weight: 400;
								font-size: 16px;
								color: #555555;
								line-height: 20px;
							}
						}
					}
					.list2 {
						margin-top: 35px;
					}
					.img2 {
						width: 100%;
						height: 180px;
						margin-top: 15px;
					}
				}
				.part4 {
					display: flex;
					flex-flow: column;
					align-items: center;
					width: 100%;
					margin-top: 130px;
					padding: 0 47px 40px 47px;
					box-sizing: border-box;
					.header {
						.text1 {
							
							font-weight: bold;
							font-size: 39px;
							color: #241934;
						}
						.cus-line {
							width: 240px;
							height: 6px;
							background: #753cbe;
							margin-top: 15px;
						}
					}
					.list {
						margin-top: 40px;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						width: 100%;
						.list-item {
							width: 300px;
							// height: 500px;
							padding: 15px;
							box-sizing: border-box;
							.img {
								width: 300px;
								height: 220px;
                                object-fit: cover;
							}
							.text2 {
								
								font-weight: bold;
								font-size: 30px;
								color: #644196;
								margin-top: 17px;
							}
							.text3 {
								margin-top: 16px;
								
								font-weight: 400;
								font-size: 20px;
								color: #555555;
								line-height: 30px;
							}
							.text4 {
								// margin-top: 10px;
								
								font-weight: 400;
								font-size: 20px;
								color: #555555;
								line-height: 30px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
